.persona-avatar {
  width: 100%;
  border: 1px solid black;
  border-radius: 50%;
  padding: 10px;
}

.persona-avatar svg {
  width: 100%;
  height: 100%;
}
