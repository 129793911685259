.mlr-submission {
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
}

.mlr-submission .list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mlr-submission .list-header {
  font-size: 16px;
  font-weight: bold;
}

.mlr-submission .list-header .count {
  font-size: 12px;
  font-weight: normal;
}

.mlr-submission .items-list {
  overflow-x: scroll;
}

.mlr-submission .item {
  width: 100%;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}

.mlr-submission .selected-item {
  border: 2px solid #5d2cc9;
}

.mlr-submission .image-container {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.mlr-submission .item-name {
  text-align: center;
  color: gray;
}

.mlr-submission .item-score-link {
  text-align: center;
  color: #5d2cc9; /* gray; */
  font-weight: 600;

  width: fit-content;
  margin: auto;
  padding: 5px 10px;
  border-radius: 5px;
}
.mlr-submission .item-score-link.perfect-score {
  border: 2px solid #008000a1;
  background-color: #eaf3e6ad;
  color: #008000a1;
}
.mlr-submission .item-score-link.normal-score {
  border: 2px solid #e2ac00;
  background-color: #f7f3d9;
  color: #e2ac00;
}
.mlr-submission .item-score-link.subpar-score {
  border: 2px solid #c11a28e0;
  background-color: #ffe8e87a;
  color: #c11a28e0;
}
.mlr-submission .item-score-link:hover {
  color: black;
}
.mlr-submission .item-score-link:hover .item-score-link-score {
  color: black;
}
/* .mlr-submission .item-score-link-score {
  font-weight: bold;
  color: #5d2cc9;
} */

.mlr-submission .checkbox-overlay {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}

.mlr-submission ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.mlr-submission ::-webkit-scrollbar-track {
  border-radius: 12px;
}
.mlr-submission ::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 12px;
}

/* Target the checkbox container */
.mlr-submission .select-all-checkbox .ant-checkbox {
  transform: scale(0.8);
}

/* Adjust the size of the checkbox input */
.mlr-submission .select-all-checkbox .ant-checkbox-input {
  width: 12px;
  height: 12px;
}

/* Adjust the label size (optional) */
.mlr-submission .select-all-checkbox .ant-checkbox-wrapper + span {
  font-size: 12px;
}
