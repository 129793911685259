.ant-modal.asset-viewer {
  width: 75% !important;
}

.asset-pdf-viewer {
  flex: 3;
  height: calc(100vh - 160px);
  overflow-y: auto;
}

.asset-pdf-viewer {
  width: 100%;
}

.asset-pdf-viewer .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.asset-pdf-viewer .react-pdf__Page__textContent.textLayer {
  width: 100% !important;
  height: 100% !important;
}

.asset-pdf-viewer .react-pdf__Page__annotations.annotationLayer {
  width: 100% !important;
  height: 100% !important;
}
