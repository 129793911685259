.element-identification-image-card .ant-collapse-item {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.element-identification-image-card .ant-collapse-content {
  flex: 1;
}
.element-identification-image-card .ant-collapse-content-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
