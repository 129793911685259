* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 12px;
}
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 12px;
}

.fullscreen-modal.ant-modal {
  width: calc(100vw - 40px) !important;
  max-width: none !important;
  top: 20px !important;
  padding-bottom: 0;
}
.fullscreen-modal .ant-modal-content {
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
.fullscreen-modal .ant-modal-body {
  height: 100%;
  overflow-y: auto;
}
