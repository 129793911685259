.image-modal .image-container:hover .overlay,
.image-modal .image-container:hover .icon-container,
.image-modal .image-container:hover .icon-container2 {
  opacity: 1;
}

.image-modal .image-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.image-modal .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* height: fit-content; */
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 8px;
}

.image-modal .icon-container {
  position: absolute;
  bottom: 17%;
  left: 7%;
  transform: translate(-50%, -90%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 8px;
}

.image-modal .icon-container2 {
  position: absolute;
  top: 2%;
  right: 2%;
  /* transform: translate(-50%, -40%); */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 8px;
}
