.content-replicator .user-message-container {
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  gap: 16px;
  padding: 16px 0;
  width: 80%;
  float: right;
}

.user-message-container .message-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-message-container .timestamp {
  font-size: 10px;
  color: #888888;
  text-align: right;
}

.user-message-container .message-icons {
  display: flex;
  align-items: end;
  gap: 5px;
}

.user-message-container .icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.7;
}
.user-message-container .edit-icon {
  width: 18px;
  height: 18px;
}

.user-message-container .icon:hover {
  opacity: 1;
}

.user-message-container .avatar {
  width: 24px;
  height: 24px;
}
