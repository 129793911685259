.custom-select-container {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 10px;
  max-width: 600px;
  font-family: Arial, sans-serif;
}

.custom-select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.custom-select-title {
  display: flex;
  align-items: center;
}

.custom-select-icon {
  font-size: 14px;
}

.custom-select-placeholder {
  margin-left: 10px;
  color: lightgray;
}

.custom-select-selected-count {
  margin-left: 10px;
}

.custom-select-dropdown {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 8px;
  margin-top: 8px;
}

.custom-select-option {
  padding: 4px 0;
}

.custom-select-selected-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.custom-select-selected-item {
  background: #f3e8ff;
  border-radius: 16px;
  padding: 4px 10px;
  margin: 5px;
  display: flex;
  align-items: center;
}

.custom-select-close-icon {
  margin-left: 8px;
  cursor: pointer;
}

.custom-select-clear-button {
  margin-left: 10px;
  font-size: 12px;
  color: #5d2cc9;
}
