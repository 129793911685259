.react-pdf-viewer {
  width: 100%;
}

.react-pdf-viewer .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf-viewer .react-pdf__Page__textContent.textLayer {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf-viewer .react-pdf__Page__annotations.annotationLayer {
  width: 100% !important;
  height: 100% !important;
}
