.predis-dialog {
  padding: 0px !important;
  margin: -9px;
}

.predis-dialog svg {
  color: black !important;
  padding: 2px 3px !important;
  background: #e1e1e1;
  border-radius: 14px;
  margin: 10px;
  top: 7px !important;
}
