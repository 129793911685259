.personalisation-modal .image-container {
  position: relative;
  margin-bottom: 2px;
}

.personalisation-modal .image-container img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}

.personalisation-modal .image-container:hover img {
  filter: brightness(50%);
}
.personalisation-modal .image-container.original-image:hover img {
  filter: brightness(100%);
}
.personalisation-modal .image-container.original-image {
  margin-bottom: 2px !important;
}
.personalisation-modal .image-container .overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 8px;
}

.personalisation-modal .image-container:hover .overlay2 {
  opacity: 1;
}

.personalisation-modal .icon-container3 {
  position: absolute;
  bottom: 0%;
  left: 11%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 8px;
}

.personalisation-modal .icon-container2 {
  position: absolute;
  top: 2%;
  right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 8px;
}

.personalisation-modal .image-container:hover .icon-container3,
.personalisation-modal .image-container:hover .icon-container2 {
  opacity: 1;
}
