.mlr-submission-description-modal .ant-btn-primary {
  background-color: transparent;
  border: 2px solid #5d2cc9;
  color: #5d2cc9;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  transition: all 0.3s ease;
}
.mlr-submission-description-modal .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #5d2cc9;
}
.mlr-submission-description-modal .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #5d2cc9;
  border-color: #5d2cc9;
}
